var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "divTemplate is-size-5" }, [
    _vm._m(0),
    _c("section", [
      _c(
        "div",
        {
          staticClass: "fullWindowHeight",
          staticStyle: { display: "flex" },
          attrs: { id: "apps" }
        },
        [
          _c("div", { staticStyle: { margin: "auto" } }, [
            _c(
              "div",
              {
                staticClass:
                  "\n\t\t\t\t\t\tappsDetail\n\t\t\t\t\t\tcolumns\n\t\t\t\t\t\tis-variable is-4\n\t\t\t\t\t\thas-text-centered\n\t\t\t\t\t\tis-desktop is-vcentered\n\t\t\t\t\t",
                staticStyle: { margin: "auto" }
              },
              [
                _c("div", { staticClass: "column" }, [
                  _c("div", { staticClass: "box" }, [
                    _c("img", {
                      staticStyle: { "max-width": "140px", margin: "25px" },
                      attrs: {
                        src: require("../../src/assets/img/yooto.png"),
                        alt: "Planning Poker logo"
                      }
                    }),
                    _c("p", { staticClass: "title is-4" }, [_vm._v("Yoo.To")]),
                    _c("br"),
                    _c(
                      "button",
                      {
                        staticClass: "button is-primary is-outlined",
                        on: {
                          click: function($event) {
                            return _vm.appNav("https://yoo.to", true)
                          }
                        }
                      },
                      [_vm._m(1), _c("span", [_vm._v("Try")])]
                    ),
                    _c("br"),
                    _c("br"),
                    _c("div", [
                      _vm._v(
                        " URL Shortner with many cool features like Branding URL, URL Notes, Redefine endpoint. "
                      )
                    ])
                  ])
                ]),
                _c("div", { staticClass: "column" }, [
                  _c("div", { staticClass: "box" }, [
                    _c("img", {
                      attrs: {
                        src: require("../../src/assets/img/chat.svg"),
                        alt: "Web Chatting logo"
                      }
                    }),
                    _c("p", { staticClass: "title is-4" }, [
                      _vm._v("Web chatting")
                    ]),
                    _c("br"),
                    _c(
                      "button",
                      {
                        staticClass: "button is-outlined is-primary",
                        on: {
                          click: function($event) {
                            return _vm.appNav("./chat/index.html")
                          }
                        }
                      },
                      [_vm._v(" Try ")]
                    ),
                    _c("br"),
                    _c("br"),
                    _c("div", [
                      _vm._v(
                        " Make new friends on open chatting rooms. Choose a room and nickname to start the fun. "
                      )
                    ])
                  ])
                ]),
                _c("div", { staticClass: "column" }, [
                  _c("div", { staticClass: "box" }, [
                    _c("img", {
                      staticStyle: { opacity: "0.8", padding: "20px" },
                      attrs: {
                        src: require("../../src/assets/img/pen.svg"),
                        alt: "TS Microblog"
                      }
                    }),
                    _c("p", { staticClass: "title is-4" }, [
                      _vm._v("TS Microblog")
                    ]),
                    _c("br"),
                    _c(
                      "button",
                      {
                        staticClass: "button is-outlined is-primary",
                        on: {
                          click: function($event) {
                            return _vm.appNav("./blog/index.html")
                          }
                        }
                      },
                      [_vm._v(" Try ")]
                    ),
                    _c("br"),
                    _c("br"),
                    _vm._m(2)
                  ])
                ])
              ]
            ),
            _c(
              "div",
              {
                staticClass:
                  "\n\t\t\t\t\t\tappsDetail\n\t\t\t\t\t\tcolumns\n\t\t\t\t\t\tis-variable is-4\n\t\t\t\t\t\thas-text-centered\n\t\t\t\t\t\tis-desktop is-vcentered\n\t\t\t\t\t"
              },
              [
                _c("div", { staticClass: "column" }, [
                  _c("div", { staticClass: "box" }, [
                    _c("img", {
                      attrs: {
                        src: require("../../src/assets/img/dices.svg"),
                        alt: "Shared Dice logo"
                      }
                    }),
                    _c("p", { staticClass: "title is-4" }, [
                      _vm._v("Shared Dice")
                    ]),
                    _c("br"),
                    _c(
                      "button",
                      {
                        staticClass: "button is-outlined is-primary",
                        on: {
                          click: function($event) {
                            return _vm.appNav("./dices/index.html")
                          }
                        }
                      },
                      [_vm._v(" Try ")]
                    ),
                    _c("br"),
                    _c("br"),
                    _c("div", [
                      _vm._v(
                        " Best roleplaying dices online. Join a group of players and you'll can see the dice result from the group members. "
                      )
                    ])
                  ])
                ]),
                _c("div", { staticClass: "column" }, [
                  _c("div", { staticClass: "box" }, [
                    _c("img", {
                      attrs: {
                        src: require("../../src/assets/img/coffee.svg"),
                        alt: "Planning Poker logo"
                      }
                    }),
                    _c("p", { staticClass: "title is-4" }, [
                      _vm._v("Planning Poker")
                    ]),
                    _c("br"),
                    _c(
                      "button",
                      {
                        staticClass: "button is-outlined is-primary",
                        on: {
                          click: function($event) {
                            return _vm.appNav("./poker/index.html")
                          }
                        }
                      },
                      [_vm._v(" Try ")]
                    ),
                    _c("br"),
                    _c("br"),
                    _c("div", [
                      _vm._v(
                        " Gamified technique for estimating, used to estimate effort or relative size of development goals in software development. "
                      )
                    ])
                  ])
                ]),
                _c("div", { staticClass: "column" }, [
                  _c("div", { staticClass: "box" }, [
                    _c("img", {
                      staticStyle: { "background-color": "black" },
                      attrs: {
                        src: require("../../src/assets/img/qrDomainBW.svg"),
                        alt: "QR Code Ticket logo"
                      }
                    }),
                    _c("p", { staticClass: "title is-4" }, [
                      _vm._v("QR Code Ticket")
                    ]),
                    _c("br"),
                    _c(
                      "button",
                      {
                        staticClass: "button is-outlined is-primary",
                        on: {
                          click: function($event) {
                            return _vm.appNav("./ticket/index.html")
                          }
                        }
                      },
                      [_vm._v(" Try ")]
                    ),
                    _c("br"),
                    _c("br"),
                    _c("div", [
                      _vm._v(
                        " Scan a QR Code to Validate a your ticket. You can also be informed if QR Code is invalid or already validated "
                      )
                    ])
                  ])
                ])
              ]
            )
          ])
        ]
      )
    ]),
    _c("section", [
      _c(
        "div",
        { staticClass: "parallax fullWindowHeight parallax1 column" },
        [
          _c("br"),
          _c("br"),
          _vm._m(3),
          _c("br"),
          _c("br"),
          _c("br"),
          _c("ContactForm")
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("section", [
      _c(
        "div",
        {
          staticClass: "parallax fullWindowHeight parallax1",
          staticStyle: { display: "flex" },
          attrs: { src: "" }
        },
        [
          _c("img", {
            staticClass: "cloud",
            attrs: {
              src: require("../../src/assets/img/cloud.svg"),
              alt: "Clouding network background"
            }
          }),
          _c(
            "div",
            {
              staticClass: "is-centered topMainText",
              staticStyle: { margin: "auto" }
            },
            [
              _c(
                "div",
                {
                  staticStyle: {
                    display: "flex",
                    height: "100vh",
                    margin: "20px"
                  }
                },
                [
                  _c("div", { staticStyle: { margin: "auto" } }, [
                    _c(
                      "div",
                      { staticStyle: { margin: "auto", "max-width": "70%" } },
                      [
                        _c("p", { staticStyle: { "font-size": "5rem" } }, [
                          _c("span", { staticClass: "has-text-primary" }, [
                            _vm._v("Hello,")
                          ]),
                          _c("br"),
                          _c("b", { staticClass: "has-text-secondary" }, [
                            _vm._v("You!")
                          ])
                        ]),
                        _c("br"),
                        _c("p", { staticStyle: { "font-size": "1.25rem" } }, [
                          _vm._v(" Here's is some of my projects. Have fun! "),
                          _c("br"),
                          _vm._v(
                            "All apps are updated continuously, don't be surprised if you see app changes while visiting. "
                          )
                        ])
                      ]
                    )
                  ])
                ]
              )
            ]
          )
        ]
      )
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "figure",
      {
        staticClass: "image is-16x16",
        staticStyle: { "margin-right": "16px", opacity: "0.5" }
      },
      [
        _c("img", {
          attrs: {
            src: require("../../src/assets/img/icons/external-link-alt-solid.svg"),
            alt: "Instagrms logo"
          }
        })
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _vm._v(" TS Microblog is the successor of Twitter. "),
      _c("br"),
      _vm._v(" TypeScripted Vue-Class-Component with MirageJS. ")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "columns is-variable is-4" }, [
      _c("div", { staticClass: "column is-two-thirds" }, [
        _c(
          "p",
          { staticClass: "title is-5 sectionTitle", attrs: { id: "bio" } },
          [_vm._v("Bio")]
        ),
        _c("div", { staticClass: "bioContent" }, [
          _vm._v(
            " I've been working in IT since 2003. From MS Office and Borland Delphi teacher, hardware maintance, Sistem analyst, SQL Server Database analyst, technical Support, customer support, Desktop and web development. "
          ),
          _c("br"),
          _c("br"),
          _vm._v(
            " The Home page and other apps were developed using VueJS. Some apps may use VueRouter, Vuex, AWS services. "
          ),
          _c("br"),
          _c("br"),
          _vm._v(
            " Projects stored on GitHub on private repository. Jenkins is WIP to continuous Deployment. Serverless Framework contribute to a effortless deploy client/server side. "
          )
        ])
      ]),
      _c(
        "div",
        { staticClass: "column contactMe", attrs: { id: "contactMe" } },
        [
          _c("p", { staticClass: "title is-5 sectionTitle" }, [
            _vm._v("Contact me")
          ]),
          _c("div", { staticClass: "columns" }, [
            _c("img", {
              staticClass: "icon is-medium",
              attrs: {
                src: require("../../src/assets/img/icons/location-outline.svg"),
                alt: "location-outline icon"
              }
            }),
            _c("span", [_vm._v("Cork, Ireland")])
          ]),
          _c("br"),
          _c("div", { staticClass: "columns" }, [
            _c("img", {
              staticClass: "icon is-medium",
              attrs: {
                src: require("../../src/assets/img/icons/mail-outline.svg"),
                alt: "mail-outline icon"
              }
            }),
            _c("span", [
              _c(
                "a",
                {
                  attrs: { href: "mailto:aesmerio@gmail.com", target: "_top" }
                },
                [_vm._v("aesmerio@gmail.com")]
              )
            ])
          ]),
          _c("br"),
          _c("div", { staticClass: "columns" }, [
            _c("img", {
              staticClass: "icon is-medium",
              attrs: {
                src: require("../../src/assets/img/icons/logo-linkedin.svg"),
                alt: "logo-linkedin icon"
              }
            }),
            _c("span", [
              _c(
                "a",
                {
                  attrs: {
                    href: "https://www.linkedin.com/in/airton-esmerio",
                    target: "_top"
                  }
                },
                [_vm._v("linkedin.com/in/airton-esmerio")]
              )
            ])
          ]),
          _c("br"),
          _c("div", { staticClass: "columns" }, [
            _c("img", {
              staticClass: "icon is-medium",
              attrs: {
                src: require("../../src/assets/img/icons/github-brands.svg"),
                alt: "logo-linkedin icon"
              }
            }),
            _c("span", [
              _c(
                "a",
                {
                  attrs: { href: "https://github.com/AEsmerio", target: "_top" }
                },
                [_vm._v("github.com/AEsmerio")]
              )
            ])
          ]),
          _c("br"),
          _c("div", { staticClass: "columns" }, [
            _c("img", {
              staticClass: "icon is-medium",
              attrs: {
                src: require("../../src/assets/img/icons/bookmark-outline.svg"),
                alt: "bookmark-outline icon"
              }
            }),
            _c("span", [
              _c(
                "a",
                { attrs: { href: "https://aesmerio.eu", target: "_top" } },
                [_vm._v("aesmerio.eu")]
              )
            ])
          ])
        ]
      )
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }