// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../src/assets/img/lines.svg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, "\n.bioContent[data-v-6c36d3c0] {\n\tmax-width: 800px;\n\tmargin-left: auto;\n\tmargin-right: auto;\n\ttext-align: justify;\n\ttext-justify: inter-word;\n}\n.icon[data-v-6c36d3c0] {\n\tmargin-right: 10px;\n}\n.appVersion[data-v-6c36d3c0] {\n\ttext-align: center;\n\tfont-size: 1rem;\n}\n.contactMe ion-icon[data-v-6c36d3c0] {\n\tmargin-right: 10px;\n}\n.sectionTitle[data-v-6c36d3c0] {\n\tpadding: 40px;\n\ttext-align: center;\n}\n.helloYou[data-v-6c36d3c0] {\n\tfont-size: 5rem;\n}\n.cloud[data-v-6c36d3c0] {\n\tposition: absolute;\n\theight: 20vmin;\n\tmax-height: 200px;\n\ttop: 18%;\n\tright: 10%;\n}\n.lines[data-v-6c36d3c0] {\n\t/* border: 1px solid red; */\n\tposition: absolute;\n\topacity: 0.02;\n}\n.parallax1[data-v-6c36d3c0] {\n\tbackground-image: linear-gradient(\n\t\t\trgba(255, 255, 255, 0.98),\n\t\t\trgba(255, 255, 255, 0.98)\n\t\t),\n\t\turl(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n}\n.appsDetail img[data-v-6c36d3c0] {\n\tmax-height: 100px;\n}\n", ""]);
// Exports
module.exports = exports;
